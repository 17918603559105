import {
    Button, Collapse, Divider, Dropdown, Icon, Input,
    Menu, message, Modal, Select, Spin, Tag,
} from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import { findAll, findById, findWhere, findWhereMultiple, getCurrentUser } from '../../../../../utils/db_functions'
import '../proposal-form.css'
import Qrp from '../../entities/qrp'
import {
    clearProductData,
    getPatternOfFinishingsComponent,
    filterComponentBySheetFinishing,
    renderProductNotes,
    getPatternOfAFinishing,
} from '../../suport-files/proposal-functions'
import productStepAccessories from './product-step-components/accessories'
import productStepAlizar from './product-step-components/alizar'
import productStepDoorLock from './product-step-components/door-lock'
import productStepFerragem from './product-step-components/ferragem'
import productStepGeneralInfo from './product-step-components/general-info'
import productStepMarco from './product-step-components/marco'
import productStepObservation from './product-step-components/observation'
import productStepPanel from './product-step-components/panel'
import productStepSheet from './product-step-components/sheet'
import productStepPriceEquilization from './product-step-components/price-equalization'
import productStepSheetNewFinishingLogic from './product-step-components/new_finishing_method/sheet'
import productStepMarcoNewFinishingLogic from './product-step-components/new_finishing_method/marco'
import productStepAlizarNewFinishingLogic from './product-step-components/new_finishing_method/alizar'

export default class ProductStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: null,
            auth: this.props.auth,

            loading: false,
            loadingMsg: null,
            dataConfigs: {},
            performanceData: {},
            sheetDimension: this.props.sheetDimension,
            alizMatriz: this.props.alizMatriz,
            doorLocksMatriz: this.props.doorLocksMatriz,
            marcoThicknessMatriz: this.props.marcoThicknessMatriz,
            panelsMatriz: this.props.panelsMatriz,
            checklistConfigs: [],
            accessories: [],

            kits: [],
            kitsComponents: {
                comp_folha: [],
                comp_folha_inativa: [],
                comp_marco: [],
                comp_marco_passagem: [],
                comp_marco_add: [],
                comp_mounting: null,
                instalation_kit: null,
            },

            // Dados do select do nome do bloco e da descrição do bloco
            addBlocoName: '',
            addBlocoDescription: '',

            // Kits select to view
            selectedKits: {},

            // Campos temporários
            temporary: {
                additionInformation: '',
                draggableItemFrom: null,
                draggableItemTo: null,
            },
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const user = await getCurrentUser()
        this.setState({ loadingMsg: 'Carregando configurações gerais...' })
        const dataConfigsArray = await findWhere('Config', 'name', [
            'tipo_componente', 'kit_amb', 'typology', 'performance', 'perfil_proposal',
            'panel_for_glass', 'substrato_proposal', 'component_model', 'bloco_nome',
            'bloco_descricao', 'modelos', 'alturas_folha', 'larguras_folha',
        ])
        const dataConfigsWithIdArray = ['typology'];
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = dataConfigsWithIdArray.includes(value.get('name')) ? value.get('array_with_id') : value.get('value')
            return acc;
        }, {})
        const marcoThicknessMatriz = await findAll('MatrizMarcoThickness')

        this.setState({ loadingMsg: 'Carregando configurações de desempenho dos kits...' })
        const performanceArray = this.props.dataConfigs['product-performanceArray'] || await findAll('ProfilePerformance')
        let performanceData = {}
        performanceArray.forEach(performance => performanceData[performance.get('profile_performance')] = performance.get('performance_level'))

        this.setState({ loadingMsg: 'Carregando acessórios...' })
        const accessories = this.props.dataConfigs['product-accessories'] || await findWhere('ComponenteKit', 'group', ['Acessórios'])

        const checklistConfigs = await findAll('ChecklistConfigs')

        this.setState({
            loading: false,
            user, dataConfigs, performanceData,
            checklistConfigs, accessories,
            marcoThicknessMatriz
        })

        this.props.setDataConfigs({
            'product-performanceArray': performanceArray,
            'product-accessories': accessories,
        })
    }

    updateTitleProductBlock(newTitle, blockIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].title = newTitle
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    updateDescriptionProductBlock(newDescription, blockIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].description = newDescription
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    createNewProductBlock() {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks = [...productBlocks, {
            title: "", products: [{
                wall_panel: [
                    {
                        panel_wall_type: null,
                        panel_wall_dimensions: null,
                        panel_wall_module: null,
                        panel_wall_finishing: null,
                        panel_wall_pattern: null,
                        panel_wall_instalation: null,
                    }
                ]
            }]
        }]
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    removeBlock(blockIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks.splice(blockIndex, 1);
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    getProductValue(attr, productBlock, productIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        return productBlocks[productBlock].products[productIndex][attr]
    }

    updateProductData(attr, blockIndex, productIndex, value, callback = function () { }) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].products[productIndex][attr] = value
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    copyProductTo(product, toBlock) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[toBlock].products = [...productBlocks[toBlock].products, { ...product }]
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    removeProduct(blockIndex, productIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].products.splice(productIndex, 1)
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    async getItemToMoveIntoBlock(event) {
        if (event.target && event.target.id) this.setState({ temporary: { ...this.state.temporary, draggableItemFrom: event.target.id } })
    }

    async getPlaceToMoveItemIntoBlock(event) {
        event.preventDefault();
        if (event.target && event.target.id) this.setState({ temporary: { ...this.state.temporary, draggableItemTo: event.target.id } })
    }

    async moveItemIntoBlock(event) {
        this.setState({
            loadingMsg: 'Movendo item, aguarde!',
            loading: true,
        });

        event.preventDefault();
        const block = this.state.temporary.draggableItemFrom.split('_')[0]

        if (block !== this.state.temporary.draggableItemTo.split('_')[0]) {
            message.error('Operação não permitida')
        } else if (this.state.temporary.draggableItemFrom && this.state.temporary.draggableItemTo) {
            let productBlocks = this.props.getValue('product_blocks')

            const fromIndex = this.state.temporary.draggableItemFrom.split('_')[1];
            const toIndex = this.state.temporary.draggableItemTo.split('_')[1];

            const product = productBlocks[block]['products'][fromIndex];

            productBlocks[block]['products'].splice(fromIndex, 1);
            productBlocks[block]['products'].splice(toIndex, 0, product);

            this.props.updateDataValue('product_blocks', productBlocks)
        }
        this.setState({ temporary: { ...this.state.temporary, draggableItemFrom: null, draggableItemTo: null } });

        this.setState({
            loadingMsg: null,
            loading: false,
        });
    }

    async findKits(productValueObject, loading = true) {
        if (loading) this.setState({ itemLoading: true, itemLoadingMsg: 'Carregando as informações do kit...' })

        let kits = []
        if (
            productValueObject.typology && productValueObject.env && productValueObject.prod_width &&
            productValueObject.profile_performance && productValueObject.prod_height
        ) {
            kits = await findWhereMultiple(
                'Kit',
                [
                    { 'field': 'typology', 'value': [productValueObject.typology, productValueObject.typology.trim()], 'op': 'containedIn', logicOp: '$and' },
                    { 'field': 'kit_environment', 'value': [productValueObject.env], 'op': 'containedIn', logicOp: '$and' },
                    { 'field': 'width', 'value': [productValueObject.prod_width], 'op': 'containedIn', logicOp: '$and' },
                    { 'field': 'height', 'value': productValueObject.prod_height, 'op': 'equalTo', logicOp: '$and' },
                    // {'field': 'height_marco', 'value': 'value', 'op': 'equalTo', logicOp: '$and'},
                    { 'field': 'performance', 'value': productValueObject.profile_performance, 'op': 'equalTo', logicOp: '$and' },
                    { 'field': 'unavailable', 'value': true, 'op': 'notEqual', logicOp: '$and' },
                ],
            )

            this.setState({ kits, itemLoading: false, itemLoadingMsg: null })

            const alizMatriz = await this.findMatrizAliz(productValueObject)
            const doorLocksMatriz = await this.findMatrizDoorLocks(productValueObject)
            const panelsMatriz = await this.findMatrizPanel(productValueObject)

            this.setState({ alizMatriz, doorLocksMatriz, panelsMatriz })
        } else this.setState({ kits, itemLoading: false, itemLoadingMsg: null })


        if (productValueObject['product_code']) await this.getAllComponentsFromKit(productValueObject['product_code'], false)
    }

    async getAllComponentsFromKit(kit, loading = true) {

        if (loading) this.setState({ itemLoading: loading, itemLoadingMsg: 'Carregando os componentes do kit...' })

        let kitsComponents = {
            'comp_folha': [],
            'comp_folha_inativa': [],
            'comp_marco': [],
            'comp_marco_passagem': [],
            'comp_marco_add': [],
            'comp_mounting': null,
            'instalation_kit': null,
        }

        if (kit) {

            const kitFound = await findById(
                'Kit',
                kit,
                ['comp_mounting', 'instalation_kit'],
                ['comp_folha', 'comp_folha_inativa', 'comp_marco', 'comp_marco_passagem', 'comp_marco_add']
            )

            if (kitFound) {
                kitsComponents = {
                    'comp_folha': kitFound.get('comp_folha') || [],
                    'comp_folha_inativa': kitFound.get('comp_folha_inativa') || [],
                    'comp_marco': kitFound.get('comp_marco') || [],
                    'comp_marco_passagem': kitFound.get('comp_marco_passagem') || [],
                    'comp_marco_add': kitFound.get('comp_marco_add') || [],
                    'comp_mounting': kitFound.get('comp_mounting'),
                    'instalation_kit': kitFound.get('instalation_kit')
                }
            }
        }

        this.setState({ itemLoading: false, itemLoadingMsg: null, kitsComponents })
    }

    async findMatrizAliz(productValueObject) {

        const alizMatriz = await findWhereMultiple(
            'MatrizAliz',
            [
                { 'field': 'typology', 'value': [productValueObject.typology], 'op': 'containedIn', logicOp: '$and' },
                { 'field': 'sheet_width', 'value': [productValueObject.prod_width], 'op': 'containedIn', logicOp: '$and' },
                { 'field': 'sheet_height', 'value': productValueObject.prod_height, 'op': 'equalTo', logicOp: '$and' },
                { 'field': 'marco_height', 'value': [productValueObject.prod_height_marco, null], 'op': 'containedIn', logicOp: '$and' },
                { 'field': 'performance', 'value': productValueObject.profile_performance, 'op': 'equalTo', logicOp: '$and' },
            ],
            null,
            [
                'component_kit',
                'component_kit_fusion_frame',
                'component_kit_with_band',
                'component_kit_with_band_fusion_frame',
                'component_kit_with_panel',
                'component_kit_with_panel_fusion_frame',
            ]
        )

        return alizMatriz
    }

    async findMatrizDoorLocks(productValueObject) {

        const doorLocksMatriz = await findWhereMultiple(
            'MatrizDoorLocks',
            [
                { 'field': 'typology', 'value': [productValueObject.typology], 'op': 'containedIn', logicOp: '$and' },
                { 'field': 'performances', 'value': productValueObject.profile_performance, 'op': 'equalTo', logicOp: '$and' },
            ],
            null,
            ['component_kit_fechadura', 'component_kit_fechadura_pf', 'component_kit_ferragens']
        )

        return doorLocksMatriz
    }

    async findMatrizPanel(productValueObject) {

        const panelsMatriz = await findWhereMultiple(
            'MatrizPanel',
            [
                { 'field': 'typology', 'value': [productValueObject.typology], 'op': 'containedIn', logicOp: '$and' },
                { 'field': 'performances', 'value': productValueObject.profile_performance, 'op': 'equalTo', logicOp: '$and' },
            ],
            null,
            ['panel_6mm', 'panel_15mm', 'panel_18mm', 'steel_frame_panel']
        )

        return panelsMatriz
    }

    renderTypology(profilePerformance, width, height, height_marco) {
        const getTypologyLabel = (id) => {
            const typologyFound = (this.state.dataConfigs.typology || []).find(el => el.value === id)
            return typologyFound && typologyFound.label
        }

        let sheetDimension = this.state.sheetDimension.filter(v => {
            return (
                v.get('performances') || []).includes(profilePerformance) &&
                v.get('width') === width && v.get('height') === height &&
                (v.get('height_marco') || v.get('height')) === height_marco
        })
        let typology = []
        sheetDimension.forEach(v => typology.push(v.get('typology')))
        typology = _.uniq(typology.flat())

        return typology
            .filter(type => getTypologyLabel(type))
            .map((type, index) => {
                return (
                    <Select.Option key={index} value={type}>{getTypologyLabel(type)}</Select.Option>
                )
            })
    }

    clearProduct(blockIndex, productIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].products[productIndex] = clearProductData(productBlocks[blockIndex].products[productIndex])
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    addNewWallPanel(blockIndex, productIndex) {
        let productBlocks = this.props.getValue('product_blocks')

        productBlocks[blockIndex].products[productIndex].wall_panel.push({
            panel_wall_alizar_type: null,
            panel_wall_type: null,
            panel_wall_dimensions: null,
            panel_wall_module: null,
            panel_wall_finishing: null,
            panel_wall_pattern: null,
            panel_wall_instalation: null,
        })

        this.props.updateDataValue('product_blocks', productBlocks)
    }

    removeWallPanel(blockIndex, productIndex, wallPanelIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].products[productIndex].wall_panel.splice(wallPanelIndex, 1)
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    getWallPanelValue(attr, blockIndex, productIndex, wallPanelIndex) {
        let productBlocks = this.props.getValue('product_blocks')
        if (productBlocks[blockIndex].products[productIndex]) {
            if (
                attr === 'panel_wall_alizar_type' ||
                attr === 'panel_wall_type' ||
                attr === 'panel_wall_dimensions' ||
                attr === 'panel_wall_finishing' ||
                attr === 'panel_wall_pattern' ||
                attr === 'panel_wall_module' ||
                attr === 'fixing_substrate' ||
                attr === 'wall_quantity'
            ) {
                return productBlocks[blockIndex].products[productIndex].wall_panel[wallPanelIndex][attr]
            }
        }
    }

    updateWallValue(attr, blockIndex, productIndex, wallPanelIndex, value) {
        let productBlocks = this.props.getValue('product_blocks')
        productBlocks[blockIndex].products[productIndex].wall_panel[wallPanelIndex][attr] = value
        this.props.updateDataValue('product_blocks', productBlocks)
    }

    updateRequiredFields(blockIndex, index, field, remove = false) {
        let required = this.props.required
        if (required.specific_products.find(x => x.block === blockIndex && x.prod === index)) {
            let req_i = required.specific_products.findIndex(x => x.block === blockIndex && x.prod === index)
            if (!remove && !required.specific_products[req_i].required.includes(field)) {
                required.specific_products[req_i].required.push(field)
            } else if (remove) {
                required.specific_products[req_i].required = required.specific_products[req_i].required.filter(el => el !== field)
            }
        } else if (!remove) {
            required.specific_products.push({ block: blockIndex, prod: index, required: [field] })
        }

        this.props.updateDataValue('required', required)
    }

    isRequiredProductComponent(blockIndex, index, field) {
        let required = this.props.required
        if (required.specific_products.find(x => x.block === blockIndex && x.prod === index)) {
            let req_i = required.specific_products.findIndex(x => x.block === blockIndex && x.prod === index)
            return required.specific_products[req_i].required.includes(field)
        } else return false
    }

    render() {
        let generalProductIndex = 0

        return (
            <div style={{ paddingTop: '40px' }}>
                <Spin spinning={this.state.loading} size='large' tip={this.state.loadingMsg || 'Carregando as informações...'}>
                    <div className='note-box' style={{ marginTop: "5px" }}>
                        <div className='note-title'>Instruções:</div>
                        <div>
                            {renderProductNotes((this.props.getValue('product_blocks') || []), this.state.checklistConfigs)}
                        </div>
                    </div>
                    {(this.props.getValue('product_blocks') || []).map((block, blockIndex, blockList) => {
                        return (
                            <div key={blockIndex} className="block-product">
                                <div className="block-product-title">
                                    <Select
                                        value={block.title || `Bloco ${blockIndex + 1}`}
                                        onChange={(value) => {
                                            if (value === 'inputOption') {
                                                let _this = this;
                                                Modal.confirm({
                                                    title: 'Novo item',
                                                    content: <Input onChange={e => _this.setState({ addBlocoName: e.target.value })} />,
                                                    okText: 'Adicionar',
                                                    okType: 'primary',
                                                    cancelText: 'Cancelar',
                                                    onOk() {
                                                        let itens = _this.state.dataConfigs.bloco_nome
                                                        let name = _this.state.addBlocoName
                                                        itens.push(name || `Novo item`)
                                                        _this.setState({
                                                            dataConfigs: { ..._this.state.dataConfigs, bloco_nome: itens },
                                                            addBlocoName: '',
                                                        })
                                                        _this.updateTitleProductBlock(name, blockIndex)
                                                    }
                                                })
                                            } else this.updateTitleProductBlock(value, blockIndex)
                                        }}
                                        placeholder="Titulo do bloco" size="large"
                                        disabled={this.props.disabledFields}
                                        className="input-block-title"
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {(this.state.dataConfigs.bloco_nome || []).map(v => {
                                            return <Select.Option value={v} key={v}>{v}</Select.Option>
                                        })}
                                        <Select.Option value='inputOption'>
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Button style={{ flex: 'none', display: 'block', width: '100%' }} type='primary'>Novo item</Button>
                                            </div>
                                        </Select.Option>
                                    </Select>

                                    <Select
                                        value={block.description}
                                        onChange={(value) => {
                                            if (value === 'inputOption') {
                                                let _this = this;
                                                Modal.confirm({
                                                    title: 'Novo item',
                                                    content: <Input onChange={e => _this.setState({ addBlocoDescription: e.target.value })} />,
                                                    okText: 'Adicionar',
                                                    okType: 'primary',
                                                    cancelText: 'Cancelar',
                                                    onOk() {
                                                        let itens = _this.state.dataConfigs.bloco_descricao
                                                        let name = _this.state.addBlocoDescription
                                                        itens.push(name || `Novo item`)
                                                        _this.setState({
                                                            dataConfigs: { ..._this.state.dataConfigs, bloco_descricao: itens },
                                                            addBlocoDescription: '',
                                                        })
                                                        _this.updateDescriptionProductBlock(name, blockIndex)
                                                    }
                                                })
                                            } else this.updateDescriptionProductBlock(value, blockIndex)
                                        }}
                                        placeholder="Descrição do bloco" size="large"
                                        disabled={this.props.disabledFields}
                                        className="input-block-title"
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {(this.state.dataConfigs.bloco_descricao || []).map(v => {
                                            return <Select.Option value={v} key={v}>{v}</Select.Option>
                                        })}
                                        <Select.Option value='inputOption'>
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Button style={{ flex: 'none', display: 'block', width: '100%' }} type='primary'>Novo item</Button>
                                            </div>
                                        </Select.Option>
                                    </Select>

                                    <Button className="remove-block-button" disabled={blockList.length === 1} type="danger" icon="close" onClick={() => this.removeBlock(blockIndex)}>
                                        Remover Bloco
                                    </Button>

                                    <Qrp
                                        dataConfigs={this.state.dataConfigs}
                                        sheetDimension={this.state.sheetDimension}
                                        generateItens={products => {
                                            let productBlocks = this.props.getValue('product_blocks')
                                            productBlocks[blockIndex].products = products
                                            this.props.updateDataValue('product_blocks', productBlocks)
                                        }}
                                        products={block.products}
                                    />
                                </div>

                                <Collapse
                                    accordion
                                    onChange={async (e) => {
                                        this.setState({ itemLoading: true })

                                        let localSelectedKits = this.state.selectedKits;
                                        localSelectedKits[blockIndex] = e;

                                        if (e) {
                                            const product = block.products[parseInt(e.toString(), 10)]
                                            this.findKits(product, false)
                                        }


                                        this.setState({ selectedKits: localSelectedKits })
                                    }}
                                >
                                    {
                                        block.products.map((v, productBlock, list) => {
                                            if (productBlock === 0 && blockIndex === 0) generalProductIndex = 0
                                            else generalProductIndex += 1

                                            let index = productBlock;
                                            const getProductValue = (attr) => {
                                                return v[attr]
                                            }
                                            const updateProductData = (attr, index, value, callback) => {
                                                this.updateProductData(attr, blockIndex, index, value, callback)
                                            }

                                            const kit = (this.state.kits || []).find(kit => kit.id === v.product_code)

                                            return (
                                                <Collapse.Panel
                                                    key={index}
                                                    header={
                                                        <div
                                                            onDragEnter={(e) => this.getPlaceToMoveItemIntoBlock(e)}
                                                        >
                                                            <div
                                                                id={`${blockIndex}_${index}`}
                                                                draggable={true}
                                                                onDragStart={(e) => this.getItemToMoveIntoBlock(e)}
                                                                onDragEnd={(e) => this.moveItemIntoBlock(e)}
                                                            >
                                                                <span>Produto {generalProductIndex + 1}</span>
                                                                <span><Tag color="green" style={{ display: 'inline', marginLeft: 16 }}>{getProductValue('project_code')}</Tag></span>
                                                                <span><Tag color="gray" style={{ display: 'inline', marginLeft: 2 }}>{getProductValue('profile_performance')} {getProductValue('typology')}</Tag></span>
                                                                <span>
                                                                    <Tag color="blue" style={{ display: 'inline', marginLeft: 2 }}>
                                                                        {getProductValue('model')} -&nbsp;
                                                                        {getProductValue('prod_height')} -&nbsp;
                                                                        {getProductValue('prod_width')} -&nbsp;
                                                                        {getProductValue('amount')}
                                                                    </Tag>
                                                                </span>

                                                                <span
                                                                    style={{ color: 'red', fontSize: 'small' }}
                                                                >
                                                                    {
                                                                        [
                                                                            ...this.props.required['product'],
                                                                            ...this.props.required['specific_products']
                                                                                .filter(el => el.block === blockIndex && el.prod === index)
                                                                                .map(el => el.required).flat()
                                                                        ].reduce((prev, current) => {
                                                                            if (!getProductValue(current)) return '* Preencha todos os campos obrigatórios'
                                                                            return prev;
                                                                        }, '')
                                                                    }
                                                                </span>

                                                                <Dropdown trigger={['click']} overlay={
                                                                    <Menu>
                                                                        <Menu.Item key="1"
                                                                            onClick={(event) => {
                                                                                this.copyProductTo(v, blockIndex)
                                                                                event.domEvent.stopPropagation()
                                                                            }}>
                                                                            <Icon type="copy" />Copiar
                                                                        </Menu.Item>
                                                                        <Menu.SubMenu key="2" title={<><Icon type="copy" />Copiar para</>}>
                                                                            {
                                                                                this.props.getValue('product_blocks').map((block, blockIndexInMenu) => {
                                                                                    return (
                                                                                        <Menu.Item onClick={event => {
                                                                                            this.copyProductTo(v, blockIndexInMenu)
                                                                                            event.domEvent.stopPropagation()
                                                                                        }} key={'copy-to-' + blockIndexInMenu} disabled={blockIndexInMenu === blockIndex}>
                                                                                            {block.title || `Bloco ${blockIndexInMenu + 1}`}
                                                                                        </Menu.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Menu.SubMenu>
                                                                        <Menu.Item key="3"
                                                                            disabled={list.length === 1}
                                                                            onClick={event => {
                                                                                event.domEvent.stopPropagation()
                                                                                this.removeProduct(blockIndex, index);
                                                                            }}>
                                                                            <Icon type="close" />Remover
                                                                        </Menu.Item>
                                                                    </Menu>
                                                                }>
                                                                    <Button onClick={(event) => event.stopPropagation()} icon="ellipsis" style={{ marginLeft: 8, float: 'right', margin: '-5px 16px' }} shape="circle" />
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    {
                                                        <Spin spinning={this.state.itemLoading || false} tip={this.state.itemLoadingMsg || 'Carregandos as informações deste item...'}>
                                                            {productStepGeneralInfo({
                                                                blockIndex,
                                                                index,
                                                                v,
                                                                getProductValue,
                                                                updateProductData,
                                                                _this: this,
                                                                updateRequiredFields: (field, remove) => this.updateRequiredFields(blockIndex, index, field, remove),
                                                            })}

                                                            <Divider dashed={true} />

                                                            <div>
                                                                {!getProductValue('search_componentKit') || getProductValue('product') === 'Folha' ?
                                                                    (kit && kit.get('sheet_finishing_new_logic')) ?
                                                                        productStepSheetNewFinishingLogic({
                                                                            index,
                                                                            getProductValue,
                                                                            updateProductData,
                                                                            getPatternOfFinishingsComponent,
                                                                            getPatternOfAFinishing,
                                                                            _this: this,
                                                                            isRequiredProductComponent: (field) => this.isRequiredProductComponent(blockIndex, index, field),
                                                                        }) :
                                                                        productStepSheet({
                                                                            index,
                                                                            getProductValue,
                                                                            updateProductData,
                                                                            getPatternOfFinishingsComponent,
                                                                            getPatternOfAFinishing,
                                                                            _this: this,
                                                                            isRequiredProductComponent: (field) => this.isRequiredProductComponent(blockIndex, index, field),
                                                                        })
                                                                    : null
                                                                }

                                                                {!getProductValue('search_componentKit') || getProductValue('product') === 'Marco' ?
                                                                    (kit && kit.get('marco_finishing_new_logic')) ?
                                                                        productStepMarcoNewFinishingLogic({
                                                                            blockIndex,
                                                                            index,
                                                                            getProductValue,
                                                                            updateProductData,
                                                                            getPatternOfFinishingsComponent,
                                                                            getPatternOfAFinishing,
                                                                            filterComponentBySheetFinishing,
                                                                            _this: this,
                                                                        }) :
                                                                        productStepMarco({
                                                                            blockIndex,
                                                                            index,
                                                                            getProductValue,
                                                                            updateProductData,
                                                                            getPatternOfFinishingsComponent,
                                                                            getPatternOfAFinishing,
                                                                            filterComponentBySheetFinishing,
                                                                            _this: this,
                                                                        }) : null
                                                                }

                                                                {!getProductValue('search_componentKit') || getProductValue('product') === 'Alizar' ?
                                                                    ((this.state.alizMatriz || []).find(el => el.get('alizar_finishing_new_logic'))) ?
                                                                        productStepAlizarNewFinishingLogic({
                                                                            index,
                                                                            getProductValue,
                                                                            updateProductData,
                                                                            getPatternOfFinishingsComponent,
                                                                            getPatternOfAFinishing,
                                                                            filterComponentBySheetFinishing,
                                                                            _this: this,
                                                                        }) :
                                                                        productStepAlizar({
                                                                            index,
                                                                            getProductValue,
                                                                            updateProductData,
                                                                            getPatternOfFinishingsComponent,
                                                                            getPatternOfAFinishing,
                                                                            filterComponentBySheetFinishing,
                                                                            _this: this,
                                                                        }) : null
                                                                }

                                                                {!getProductValue('search_componentKit') || getProductValue('product') === 'Painel' ?
                                                                    productStepPanel({
                                                                        blockIndex,
                                                                        index,
                                                                        v,
                                                                        getPatternOfFinishingsComponent,
                                                                        getPatternOfAFinishing,
                                                                        _this: this,
                                                                        getProductValue,
                                                                    }) : null
                                                                }

                                                                {!getProductValue('search_componentKit') || getProductValue('product') === 'Fechadura' ?
                                                                    productStepDoorLock({
                                                                        index,
                                                                        getProductValue,
                                                                        updateProductData,
                                                                        _this: this,
                                                                        updateRequiredFields: (field, remove) => this.updateRequiredFields(blockIndex, index, field, remove),
                                                                        isRequiredProductComponent: (field) => this.isRequiredProductComponent(blockIndex, index, field),
                                                                    }) : null
                                                                }

                                                                {!getProductValue('search_componentKit') || getProductValue('product') === 'Ferragem' ?
                                                                    productStepFerragem({
                                                                        blockIndex,
                                                                        index,
                                                                        getProductValue,
                                                                        updateProductData,
                                                                        _this: this,
                                                                        updateRequiredFields: (field, remove) => this.updateRequiredFields(blockIndex, index, field, remove),
                                                                        isRequiredProductComponent: (field) => this.isRequiredProductComponent(blockIndex, index, field),
                                                                    }) : null
                                                                }

                                                                {!getProductValue('search_componentKit') ?
                                                                    productStepAccessories({
                                                                        index,
                                                                        getProductValue,
                                                                        updateProductData,
                                                                        _this: this,
                                                                        updateRequiredFields: (field, remove) => this.updateRequiredFields(blockIndex, index, field, remove),
                                                                        isRequiredProductComponent: (field) => this.isRequiredProductComponent(blockIndex, index, field),
                                                                    }) : null
                                                                }

                                                                {productStepPriceEquilization({
                                                                    index,
                                                                    getProductValue,
                                                                    updateProductData,
                                                                    _this: this,
                                                                })}

                                                                {productStepObservation({ index, getProductValue, updateProductData })}
                                                            </div>

                                                        </Spin>
                                                    }
                                                </Collapse.Panel>

                                            )

                                        })
                                    }
                                </Collapse>

                                <div className="add-product-button">
                                    <Button title="Adicionar um novo produto a este bloco." icon="plus" type="primary" onClick={event => {
                                        let productBlocks = this.props.getValue('product_blocks')
                                        productBlocks[blockIndex].products.push({
                                            wall_panel: [
                                                {
                                                    panel_wall_type: null,
                                                    panel_wall_dimensions: null,
                                                    panel_wall_module: null,
                                                    panel_wall_finishing: null,
                                                    panel_wall_pattern: null,
                                                    panel_wall_instalation: null,
                                                }
                                            ]
                                        })
                                        this.props.updateDataValue('product_blocks', productBlocks)
                                    }}>Adicionar Produto</Button>
                                </div>
                            </div>
                        )
                    })}

                    <Divider>
                        <Button title="Adicionar um novo bloco." icon="plus" onClick={() => this.createNewProductBlock()}>
                            Adicionar um novo bloco
                        </Button>
                    </Divider>
                </Spin>
            </div>
        )
    }

}