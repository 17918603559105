import { Col, Divider, Form, Row, Select } from "antd"
import _ from "lodash"
import { getLabelFromValue } from "../../../../../components-and-kits/matriz-door-locks/suport-files/door-locks-matriz-functions"
import { doorLockCompositionLabelAndValue, doorLockCompositionOptions } from "../../../../../components-and-kits/matriz-door-locks/suport-files/door-locks-select-options"
import React, { Fragment } from "react"

const updateRequiredDoorLockField = (composition, updateRequiredFunction) => {
    if ((composition || '').includes('kit')) {
        updateRequiredFunction('conj_fechadura', false)
        updateRequiredFunction('hardware_door_lock', true)
    } else {
        updateRequiredFunction('hardware_door_lock', false)
        updateRequiredFunction('conj_fechadura', true)
    }
}

const productStepDoorLock = ({
    index,
    getProductValue,
    updateProductData,
    _this,
    updateRequiredFields,
    isRequiredProductComponent,
}) => {
    return (
        <Fragment>
            <h2>Cadastro de Fechadura</h2>

            <Row gutter={24}>
                <Col span={4}>
                    <Form.Item label='Composição da Fechadura'>
                        <Select
                            disabled={_this.props.disabledFields}
                            value={getProductValue('door_lock_composition')}
                            onChange={value => {
                                updateProductData('door_lock_composition', index, value);

                                ['conj_fechadura', 'hardware_door_lock', 'hardware_door_handle'].forEach(attrToClear => {
                                    updateProductData(attrToClear, index, null)
                                });

                                updateRequiredDoorLockField(value, updateRequiredFields)
                            }}
                            placeholder='Composição da Fechadura' size="large"
                        >
                            {
                                doorLockCompositionOptions(
                                    _this.state.doorLocksMatriz,
                                    _this.props.getValue("is_company"),
                                ).map(el => {
                                    return (<Select.Option value={el.value}>{el.label}</Select.Option>)
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                {(getProductValue('door_lock_composition') || "").includes('kit') ?
                    <Col span={12}>
                        <Form.Item label='Fechadura/Usinagem' required={isRequiredProductComponent('conj_fechadura')}>
                            <Select
                                disabled={_this.props.disabledFields}
                                value={getProductValue('conj_fechadura')}
                                onChange={async value => {
                                    updateProductData('conj_fechadura', index, value)
                                    updateRequiredFields('lock_quantity', !value)
                                    if (!getProductValue('lock_quantity')) updateProductData('lock_quantity', index, 1)
                                }}
                                placeholder="Fechadura" size="large">
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    _.uniqBy((_this.state.doorLocksMatriz || [])
                                        .map(matriz => {
                                            const kitFechaduraMatrizField = _this.props.getValue("is_company") ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
                                            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
                                        })
                                        .flat()
                                        .filter(ferragem => (
                                            ferragem && (
                                                ferragem.get('group') === 'Conjunto Fechadura' ||
                                                ferragem.get('group') === 'Kit Fechadura'
                                            )
                                        ))
                                        .map(el => el.toJSON())
                                        .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                        , 'objectId')
                                        .map(el => {
                                            return (
                                                <Select.Option value={el['objectId']}>{el['description_component']}</Select.Option>
                                            );
                                        })
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    :
                    <Col span={12}>
                        <Form.Item label='Fechadura/Usinagem' required={isRequiredProductComponent('hardware_door_lock')}>
                            <Select
                                disabled={_this.props.disabledFields}
                                value={getProductValue('hardware_door_lock')}
                                onChange={async value => {
                                    updateProductData('hardware_door_lock', index, value)
                                    updateRequiredFields('lock_quantity', !value)
                                    if (!getProductValue('lock_quantity')) updateProductData('lock_quantity', index, 1)
                                }}
                                placeholder="Fechadura" size="large"
                            >
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    _.uniqBy((_this.state.doorLocksMatriz || [])
                                        .map(matriz => {
                                            const kitFechaduraMatrizField = _this.props.getValue("is_company") ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
                                            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
                                        })
                                        .flat()
                                        .filter(ferragem => (
                                            ferragem && ferragem.get('group') === 'Fechadura') && getProductValue('door_lock_composition') &&
                                            (
                                                (getProductValue('door_lock_composition') === 'usinagem' && ferragem.get('description_component').toLowerCase().includes('usinagem')) ||
                                                (getProductValue('door_lock_composition').includes('fech') && !ferragem.get('description_component').toLowerCase().includes('usinagem'))
                                            )
                                        )
                                        .map(el => el.toJSON())
                                        .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                        , 'objectId')
                                        .map(el => {
                                            return (
                                                <Select.Option value={el['objectId']}>{el['description_component']}</Select.Option>
                                            );
                                        })
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                }

                <Col span={8}>
                    <Form.Item label='Maçaneta/Puxador' required={isRequiredProductComponent('hardware_door_handle')}>
                        <Select
                            disabled={
                                !(
                                    (getLabelFromValue(doorLockCompositionLabelAndValue, getProductValue('door_lock_composition')) || '').toLowerCase().includes('maçaneta') ||
                                    (getLabelFromValue(doorLockCompositionLabelAndValue, getProductValue('door_lock_composition')) || '').toLowerCase().includes('puxador')
                                ) ||
                                _this.props.disabledFields
                            }
                            value={getProductValue('hardware_door_handle')}
                            onChange={async value => {
                                updateProductData('hardware_door_handle', index, value)
                            }}
                            placeholder="Maçaneta/Puxador" size="large"
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                _.uniqBy((_this.state.doorLocksMatriz || [])
                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                    .flat()
                                    .filter(ferragem => {
                                        const isMacaneta = (getLabelFromValue(doorLockCompositionLabelAndValue, getProductValue('door_lock_composition')) || '').includes('Maçaneta')
                                        const isPuxador = (getLabelFromValue(doorLockCompositionLabelAndValue, getProductValue('door_lock_composition')) || '').includes('Puxador')
                                        const addCategory = isMacaneta ? 'Maçaneta' : (isPuxador ? 'Puxador' : null)

                                        return ferragem && addCategory && ferragem.get('add_category') === addCategory
                                    })
                                    .map(el => el.toJSON())
                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                    , 'objectId'
                                )
                                    .map(el => {
                                        return (
                                            <Select.Option value={el['objectId']}>{el['description_component']}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Divider dashed={true} />
        </Fragment >
    )
}

export default productStepDoorLock