import { Col, Divider, Form, Row, Select, InputNumber, Button, message } from "antd";
import React, { Fragment } from "react";

const productStepAccessories = ({
  index,
  getProductValue,
  updateProductData,
  _this,
  updateRequiredFields,
  isRequiredProductComponent,
}) => {
  const options = [
    { label: "Vidro Temperado", value: "Vidro Temperado", },
    { label: "Vidro Acústico", value: "Vidro Acústico", },
    { label: "Vidro Antiradiações", value: "Vidro Antiradiações", },
    { label: "Grelha Alumínio", value: "Grelha Alumínio", },
    { label: "Canopla PVC HD", value: "Canopla PVC HD", },
    { label: "Sign Serrambi", value: "Sign Serrambi", },
    { label: "Bandeira Vidro 2700", value: "Bandeira Vidro 2700", },
    { label: "Bandeira Fixa 2700", value: "Bandeira Fixa 2700", },
  ]

  const checkIfHasComponentToList = (value) => {
    return (_this.state.accessories || [])
      .filter((accessory) => 
        accessory.get("description_component").toLowerCase()
        .includes(value.toLowerCase())
      ).length > 0;
  }

  return (
    <Fragment>
      <h2>
        Cadastro de Acessórios
        {(getProductValue("accessoriesArray") || []).length < options.filter((el) => checkIfHasComponentToList(el.label)).length &&
          <Button
            icon="plus" shape="circle" size="large" disabled={_this.props.disabledFields} style={{ marginLeft: 16 }}
            onClick={() => {
              let accessories = getProductValue("accessoriesArray") || [];
              accessories.push({ accessoryType: null, accessory: null, accessory_amount: null });
              updateProductData("accessoriesArray", index, accessories);
            }}
          />
        }
      </h2>

      {(getProductValue("accessoriesArray") || []).map(
        (accessory, accessoryIndex) => {
          return (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Tipo">
                  <Select
                    value={
                      getProductValue("accessoriesArray")[accessoryIndex]
                        .accessoryType
                    }
                    onChange={async (value) => {
                      if (getProductValue("accessoriesArray").map(el => el.accessoryType).includes(value))
                        return message.info("Acessório já adicionado!");

                      accessory.accessoryType = value;
                      accessory.accessory = value;
                      let optionAccessories = getProductValue("accessoriesArray") || [];
                      optionAccessories[accessoryIndex] = { accessoryType: value, accessory: null };

                      updateProductData("accessoriesArray", index, optionAccessories);
                    }}
                    placeholder="Tipo"
                    size="large"
                    disabled={_this.props.disabledFields}
                  >
                    <Select.Option value={null}>Selecione...</Select.Option>
                    {options
                      .filter((el) => checkIfHasComponentToList(el.label))
                      .map((el) => {
                        return (
                          <Select.Option value={el["value"]}>
                            {el["label"]}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={14}>
                <Form.Item label="Acessório">
                  <Select
                    disabled={_this.props.disabledFields}
                    value={
                      getProductValue("accessoriesArray")[accessoryIndex]
                        .accessory
                    }
                    onChange={(value) => {
                      let accessories = getProductValue("accessoriesArray");
                      accessories[accessoryIndex].accessory = value;
                      updateProductData("accessoriesArray", index, accessories);
                    }}
                    placeholder="Acessório"
                    size="large"
                    mode="default"
                  >
                    <Select.Option value={null}>Selecione...</Select.Option>
                    {(_this.state.accessories || [])
                      .filter((accessory) => {
                        return accessory
                          .get("description_component")
                          .toLowerCase()
                          .includes(
                            (
                              (getProductValue("accessoriesArray")[
                                accessoryIndex
                              ] &&
                                getProductValue("accessoriesArray")[
                                  accessoryIndex
                                ].accessoryType) ||
                              ""
                            ).toLowerCase()
                          );
                      })
                      .map((accessory) => {
                        return (
                          <Select.Option value={accessory.id}>
                            {accessory.get("product_code")} -{" "}
                            {accessory.get("description_component")}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item label="Qtd x Kit">
                  <InputNumber
                    value={
                      getProductValue("accessoriesArray")[accessoryIndex]
                        .accessory_amount
                    }
                    max={3}
                    step={1}
                    onChange={async (value) => {
                      let accessories = getProductValue("accessoriesArray");
                      accessories[accessoryIndex].accessory_amount = value;
                      updateProductData("accessoriesArray", index, accessories);
                    }}
                    placeholder="Quantidade de acessórios"
                    size="large"
                    disabled={_this.props.disabledFields}
                  />
                </Form.Item>
              </Col>

              <Col span={2}>
                <Button
                  style={{ marginTop: "45px", width: "100%" }}
                  type="danger"
                  onClick={() => {
                    let accessories = getProductValue("accessoriesArray") || [];
                    accessories.splice(accessoryIndex, 1);
                    updateProductData("accessoriesArray", index, accessories);
                  }}
                >
                  Excluir
                </Button>
              </Col>
            </Row>
          );
        }
      )}

      <Divider dashed={true} />
    </Fragment>
  );
};

export default productStepAccessories;
