import { Col, Divider, Form, InputNumber, Row, Select, Button, message } from "antd";
import _ from "lodash";
import React, { Fragment } from "react";

const productStepFerragem = ({
  blockIndex,
  index,
  getProductValue,
  updateProductData,
  _this,
  updateRequiredFields,
  isRequiredProductComponent,
}) => {

  const options = [
    { label: "Puxador", value: "hardware_puller" },
    { label: "Puxador PCD", value: "hardware_bar_anti_panic" },
    { label: "Mola Aérea", value: "hardware_aerial_spring" },
    { label: "Batedor", value: "hardware_accessories" },
    { label: "Olho mágico", value: "hardware_accessories_02" },
    { label: "Fecho de segurança", value: "hardware_accessories_03" },
  ]

  const getAmountField = (field) => {
    switch (field) {
      case "hardware_puller":
        return "hardware_puller_amount";
      case "hardware_bar_anti_panic":
        return "hardware_bar_anti_panic_amount";
      case "hardware_aerial_spring":
        return "hardware_aerial_spring_amount";
      case "hardware_accessories":
        return "hardware_accessories_amount";
      case "hardware_accessories_02":
        return "hardware_accessories_amount_02";
      case "hardware_accessories_03":
        return "hardware_accessories_amount_03";
      default:
        return null;
    }
  };

  const setQtdAndUpdateRequired = (field, value) => {
    const amountField = getAmountField(field);

    updateRequiredFields(amountField, !value);
    if (!getProductValue(amountField)) updateProductData(amountField, index, 1);
  };

  const checkIfHasComponentToList = (value) => {
    return (_this.state.doorLocksMatriz || [])
      .map((matriz) => matriz.get("component_kit_ferragens"))
      .flat()
      .filter((ferragem) => ferragem.get("add_category") === value).length > 0;
  }

  return (
    <Fragment>
      <h2>
        Cadastro de Ferragens
        {(getProductValue("option_ferragem_with_label_and_value") || []).length < options.filter((el) => checkIfHasComponentToList(el.label)).length &&
          <Button
            icon="plus" shape="circle" size="large" disabled={_this.props.disabledFields} style={{ marginLeft: 16 }}
            onClick={() => {
              let optionFerragens = getProductValue("option_ferragem_with_label_and_value") || [];
              optionFerragens.push({ value: null, label: null });
              updateProductData("option_ferragem_with_label_and_value", index, optionFerragens);
            }}
          />
        }
      </h2>

      {(getProductValue("option_ferragem_with_label_and_value") || []).map((optionFerragem, optioFerragemIndex) => {
        return (
          <Row gutter={24} key={optioFerragemIndex}>
            <Col span={6}>
              <Form.Item label="Tipo">
                <Select
                  value={optionFerragem.value}
                  onChange={async (value) => {
                    if (getProductValue("option_ferragem_with_label_and_value").map(el => el.value).includes(value))
                      return message.info("Ferragem já adicionada!");

                    optionFerragem.value = value;
                    optionFerragem.label = options.find((el) => el.value === value).label;
                    let optionFerragens = getProductValue("option_ferragem_with_label_and_value") || [];
                    optionFerragens[optioFerragemIndex] = { value, label: options.find((el) => el.value === value).label };

                    updateProductData("option_ferragem_with_label_and_value", index, optionFerragens);
                    updateProductData("option_ferragem", index, optionFerragens.map(el => el.value));

                    setQtdAndUpdateRequired(optionFerragem.value, value);
                  }}
                  placeholder="Tipo"
                  size="large"
                  disabled={_this.props.disabledFields}
                >
                  <Select.Option value={null}>Selecione...</Select.Option>
                  {options
                    .filter((el) => checkIfHasComponentToList(el.label))
                    .map((el) => {
                      return (
                        <Select.Option value={el["value"]}>
                          {el["label"]}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="Ferragem">
                <Select
                  value={getProductValue(optionFerragem.value)}
                  onChange={async (value) => {
                    updateProductData(optionFerragem.value, index, value);
                    setQtdAndUpdateRequired(optionFerragem.value, value);
                  }}
                  placeholder="Ferragem"
                  size="large"
                  disabled={_this.props.disabledFields}
                >
                  <Select.Option value={null}>Selecione...</Select.Option>
                  {_.uniqBy(
                    (_this.state.doorLocksMatriz || [])
                      .map((matriz) => matriz.get("component_kit_ferragens"))
                      .flat()
                      .filter((ferragem) => ferragem.get("add_category") === optionFerragem.label)
                      .map((el) => el.toJSON())
                      .sort((a, b) =>
                        a.description_component > b.description_component
                          ? 1
                          : b.description_component > a.description_component
                            ? -1
                            : 0
                      ),
                    "objectId"
                  ).map((el) => {
                    return (
                      <Select.Option value={el["objectId"]}>
                        {el["description_component"]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="Qtd x Kit"
                required={isRequiredProductComponent(`${optionFerragem.value}_amount`)}
              >
                <InputNumber
                  value={getProductValue(`${optionFerragem.value}_amount`)}
                  max={3}
                  step={1}
                  onChange={async (value) => {
                    updateProductData(`${optionFerragem.value}_amount`, index, value);
                    if (!value) updateProductData(optionFerragem.value, index, null);
                  }}
                  placeholder="Quantidade"
                  size="large"
                  disabled={_this.props.disabledFields}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button
                style={{ marginTop: "45px", width: "100%" }}
                type="danger"
                onClick={() => {
                  let optionFerragens = getProductValue("option_ferragem_with_label_and_value") || [];
                  optionFerragens = optionFerragens.filter(
                    (el, i) => i !== optioFerragemIndex
                  );
                  updateProductData("option_ferragem_with_label_and_value", index, optionFerragens);

                  updateProductData(optionFerragem.value, index, null);
                  updateProductData(`${optionFerragem.value}_amount`, index, null);
                }}
              >
                Excluir
              </Button>
            </Col>
          </Row>
        );
      })}

      <Divider dashed={true} />
    </Fragment>
  );
};

export default productStepFerragem;
