import { hasUsinagem, hasDoorLock, hasDoorLockKit, hasHardwareDoorHandle } from "./door-locks-matriz-functions"
// ATENÇÃO: Ao excluir ou adicionar itens, verificar o impacto no arquivo door-locks-matriz-functions.js

const doorLockTypology = [
    'Fechadura trinco normal - TN',
    'Fechadura trinco rolete - TR',
    'Fechadura de correr - FC',
    'Fechadura auxiliar - FA',
]

const doorLockType = [
    'Mecânica',
    'Magnética',
    'Eletrônica',
    'Digital',
    'Segurança',
]

const doorLockComposition = [
    'Fechadura + Maçaneta',
    'Fechadura + Puxador',
    'Kit Fechadura',
    'Kit Fechadura + Puxador',
    'Kit Fechadura PCD',
    'Fechadura auxiliar',
    'Usinagem Fechadura',
]

// ATENÇÃO: Ao excluir ou adicionar itens, verificar o impacto no arquivo door-locks-matriz-functions.js

const doorLockTypologyLabelAndValue = [
    { label: 'Fechadura trinco normal - TN', value: 'tn' },
    { label: 'Fechadura trinco rolete - TR', value: 'tr' },
    { label: 'Fechadura de Correr - FC', value: 'fc' },
    { label: 'Fechadura Auxiliar - FA', value: 'fa' },
    { label: 'Fechadura Magnética - FM', value: 'fn' },
    { label: 'Barra Antipanico - BA', value: 'ba' },
    { label: 'Fechadura Manutenção - FA', value: 'ft' },
    { label: 'Fechadura Livre-Ocupado - LO', value: 'lo' },
    { label: 'Usinagem CNC Fechadura - UF', value: 'uf' },
]

const doorLockTypeLabelAndValue = [
    { label: 'Mecânica', value: 'mec' },
    { label: 'Magnética', value: 'mag' },
    { label: 'Digital', value: 'dig' },
]

const doorLockCompositionLabelAndValue = [
    { label: 'Usinagem', value: 'usinagem' },
    { label: 'Fechadura', value: 'fech' },
    { label: 'Fechadura + Maçaneta', value: 'fech_mac' },
    { label: 'Fechadura + Puxador', value: 'fech_pux' },
    { label: 'Kit Fechadura', value: 'kit' },
    { label: 'Kit Fechadura PCD', value: 'kit_pcd' },
    { label: 'Kit Fechadura PSE', value: 'kit_pse' },
    { label: 'LockApp', value: 'kit_lock_app' },
]

const doorLockCompositionOptions = (doorLocksMatriz, isCompany) => {
    let options = [];
    if(hasUsinagem(doorLocksMatriz, isCompany)) options = [...options, { label: 'Usinagem', value: 'usinagem' }];
    if(hasDoorLock(doorLocksMatriz, isCompany)) options = [...options, { label: 'Fechadura', value: 'fech' }];
    if(hasHardwareDoorHandle(doorLocksMatriz, isCompany, 'maçaneta')) options = [...options, { label: 'Fechadura + Maçaneta', value: 'fech_mac' }]
    if(hasHardwareDoorHandle(doorLocksMatriz, isCompany, 'puxador')) options = [...options, { label: 'Fechadura + Puxador', value: 'fech_pux' }]
    if(hasDoorLockKit(doorLocksMatriz, isCompany)) options = [...options, { label: 'Kit Fechadura', value: 'kit' }];
    return options;
}

export {
    doorLockTypology,
    doorLockType,
    doorLockComposition,
    doorLockTypologyLabelAndValue,
    doorLockTypeLabelAndValue,
    doorLockCompositionLabelAndValue,
    doorLockCompositionOptions,
}