import _ from "lodash"
const { doorLockComposition } = require("./door-locks-select-options")

const kitOrDoorLock = (doorLockCompositionToCheck) => {
    const doorLockCompositionToCheckIndex = doorLockComposition.findIndex(el => el === doorLockCompositionToCheck)

    const kitsIndex = [2, 3, 4]
    // const doorLockIndex = [0, 1, 5, 6]

    if (kitsIndex.includes(doorLockCompositionToCheckIndex)) return 'kit'
    return 'doorLock'
}

const getLabelFromValue = (array, value) => {
    const element = array.find(el => el.value === value)
    return element && element.label
}

const hasUsinagem = (doorLocksMatriz, isCompany) => {
    return _.uniqBy((doorLocksMatriz || [])
        .map(matriz => {
            const kitFechaduraMatrizField = isCompany ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
        })
        .flat()
        .filter(ferragem => (
            ferragem && 
            (ferragem.get('description_component').toLowerCase().includes('usinagem'))  &&
            ferragem.get('group') === 'Fechadura'
        ))
    ).length > 0
}

const hasDoorLock = (doorLocksMatriz, isCompany) => {
    return _.uniqBy((doorLocksMatriz || [])
        .map(matriz => {
            const kitFechaduraMatrizField = isCompany ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
        })
        .flat()
        .filter(ferragem => (
            ferragem && 
            (ferragem.get('group') === 'Fechadura')
        ))
    ).length > 0
}

const hasDoorLockKit = (doorLocksMatriz, isCompany) => {
    return _.uniqBy((doorLocksMatriz || [])
        .map(matriz => {
            const kitFechaduraMatrizField = isCompany ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
        })
        .flat()
        .filter(ferragem => (
            ferragem && 
            (ferragem.get('group') === 'Conjunto Fechadura' || ferragem.get('group') === 'Kit Fechadura')
        ))
    ).length > 0
}

const hasHardwareDoorHandle = (doorLocksMatriz, isCompany, doorHandle) => {
    return _.uniqBy((doorLocksMatriz || [])
        .map(matriz => matriz.get('component_kit_ferragens'))
        .flat()
        .filter(ferragem => (
            ferragem && 
            ferragem.get('description_component').toLowerCase().includes(doorHandle)
        ))
    ).length > 0
}

export {
    kitOrDoorLock,
    getLabelFromValue,
    hasUsinagem,
    hasDoorLock,
    hasDoorLockKit,
    hasHardwareDoorHandle
}